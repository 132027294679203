<template>
  <div class="personalData">
    <div class="education-area per-area">
      <div class="module-head" @click="stretchEvent('edu')">教育经历
        <img class="stretch-btn" v-show="!isShowEdu" src="../../assets/img/toBottom.png" alt="">
        <img class="stretch-btn" v-show="isShowEdu" src="../../assets/img/toTop.png" alt="">
      </div>
      <transition name = "fade">
        <div v-show="isShowEdu">
          <div class="module-cont" v-for="(item, index) in eduData" :key="index">
            <div class="module-cont-title">
              教育经历{{index+1}}
              <div>
                <span class="deleteEle" @click="deleteEvent(index, 'edu')">删除</span>
                <span v-show="eduIndex != index" @click="editEvent(index, 'edu')">编辑</span>
                <span v-show="eduIndex == index" @click="enterEvent">保存</span>
              </div>
            </div>
            <div class="item-cont">
              <van-form validate-first @failed="onFailed" :readonly="eduIndex != index">
                <van-field
                  :clickable="eduIndex == index"
                  v-model="item.name"
                  name="用户名"
                  label="学校名称"
                  placeholder="学校名称"
                  required
                  @input="trimLR('name', 'eduData', index)"
                  :rules="[{ required: true, message: '请填写学校名称' }]"
                />
                <van-field
                  readonly
                  required
                  :clickable="eduIndex == index"
                  name="datetimePicker"
                  :value="item.startTime"
                  label="开始时间"
                  placeholder="点击选择时间"
                  @click="showTime(index, 'start')"
                />
                <van-field
                  readonly
                  required
                  :clickable="eduIndex == index"
                  name="datetimePicker"
                  :value="item.endTime"
                  label="结束时间"
                  placeholder="点击选择时间"
                  @click="showTime(index, 'end')"
                />
                <van-field
                  readonly
                  required
                  :clickable="eduIndex == index"
                  name="picker"
                  :value="item.position"
                  label="学历"
                  placeholder="点击选择学历"
                  @click="showPosition(index)"
                />
                <van-field
                  :clickable="eduIndex == index"
                  v-model="item.reference"
                  @input="trimLR('reference', 'eduData', index)"
                  name="证明人"
                  label="证明人"
                  placeholder="证明人"
                />
              </van-form>
            </div>
          </div>
          <div class="add-module-area" @click="addEvent('edu')">+ 继续增加教育经历</div>
        </div>
      </transition>
    </div>
    <div class="work-area per-area">
      <div class="module-head" @click="stretchEvent('work')">工作经历 
        <img class="stretch-btn" v-show="!isShowWork" src="../../assets/img/toBottom.png" alt="">
        <img class="stretch-btn" v-show="isShowWork" src="../../assets/img/toTop.png" alt="">
      </div>
      <transition name = "fade">
        <div v-show="isShowWork">
          <div class="module-cont" v-for="(item, index) in workData" :key="index">
            <div class="module-cont-title">
              工作经历{{index+1}}
              <div>
                <span class="deleteEle" @click="deleteEvent(index, 'work')">删除</span>
                <span v-show="workIndex != index" @click="editEvent(index, 'work')">编辑</span>
                <span v-show="workIndex == index" @click="enterWorkEvent">保存</span>
              </div>
            </div>
            <div class="item-cont">
              <van-form validate-first @failed="onFailed" :readonly="workIndex != index">
                <van-field
                  :clickable="workIndex == index"
                  v-model="item.name"
                  name="单位名称"
                  label="单位名称"
                  placeholder="单位名称"
                  required
                  @input="trimLR('name', 'workData', index)"
                  :rules="[{ required: true, message: '请填写单位名称' }]"
                />
                <van-field
                  readonly
                  required
                  :clickable="workIndex == index"
                  name="datetimePicker"
                  :value="item.startTime"
                  label="开始时间"
                  placeholder="点击选择时间"
                  @click="showTime(index, 'start')"
                />
                <van-field
                  :readonly="true"
                  required
                  :clickable="workIndex == index"
                  name="datetimePicker"
                  :value="item.endTime"
                  label="结束时间"
                  placeholder="点击选择时间"
                  @click="showTime(index, 'end')"
                />
                <van-field
                  :clickable="workIndex == index"
                  v-model="item.position"
                  name="职务"
                  label="职务"
                  placeholder="职务"
                  required
                  @input="trimLR('position', 'workData', index)"
                  :rules="[{ required: true, message: '请填写职务' }]"
                />
                <van-field
                  :clickable="workIndex == index"
                  v-model="item.reference"
                  name="证明人"
                  label="证明人"
                  placeholder="证明人"
                  @input="trimLR('reference', 'workData', index)"
                />
              </van-form>
            </div>
          </div>
          <div class="add-module-area" @click="addEvent('work')">+ 继续增加工作经历</div>
        </div>
      </transition>
    </div>
    <div class="family-area per-area">
      <div class="module-head" @click="stretchEvent('family')">家庭成员 
        <img class="stretch-btn" v-show="!isShowFamily" src="../../assets/img/toBottom.png" alt="">
        <img class="stretch-btn" v-show="isShowFamily" src="../../assets/img/toTop.png" alt="">
      </div>
      <transition name = "fade">
        <div v-show="isShowFamily">
          <div class="module-cont" v-for="(item, index) in familyData" :key="index">
            <div class="module-cont-title">
              家庭成员{{index+1}}
              <div>
                <span class="deleteEle" @click="deleteEvent(index, 'family')">删除</span>
                <span v-show="familyIndex != index" @click="editEvent(index, 'family')">编辑</span>
                <span v-show="familyIndex == index" @click="enterfamilyEvent">保存</span>
              </div>
            </div>
            <div class="item-cont">
              <van-form validate-first @failed="onFailed" :readonly="familyIndex != index">
                <van-field
                  :clickable="familyIndex == index"
                  v-model="item.name"
                  name="姓名"
                  label="姓名"
                  placeholder="姓名"
                  required
                  @input="trimLR('name', 'familyData', index)"
                  :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                  :clickable="familyIndex == index"
                  v-model="item.relation"
                  name="与本人关系（称谓）"
                  label="与本人关系（称谓）"
                  placeholder="与本人关系（称谓）"
                  required
                  @input="trimLR('relation', 'familyData', index)"
                  :rules="[{ required: true, message: '请填写与本人关系（称谓）' }]"
                />
                <van-field
                  :clickable="familyIndex == index"
                  v-model="item.age"
                  type="digit"
                  name="年龄"
                  label="年龄"
                  placeholder="年龄"
                  required
                  maxlength="3"
                  @input="trimLR('age', 'familyData', index)"
                  :rules="[{ required: true, message: '请填写年龄' }]"
                />
                <van-field
                  readonly
                  required
                  :clickable="familyIndex == index"
                  name="picker"
                  :value="item.sex"
                  label="性别"
                  placeholder="点击选择性别"
                  @click="showSex(index)"
                />
                <van-field
                required
                  :clickable="familyIndex == index"
                  v-model="item.unit"
                  name="工作单位"
                  label="工作单位"
                  placeholder="请输入工作单位"
                  @input="trimLR('unit', 'familyData', index)"
                />
                <van-field
                required
                  :clickable="familyIndex == index"
                  v-model="item.phone"
                  type="tel"
                  name="联系方式"
                  label="联系方式"
                  placeholder="请输入联系方式"
                  maxlength="20"
                  @input="trimLR('phone', 'familyData', index)"
                />
                <van-field
                  :clickable="familyIndex == index"
                  v-model="item.address"
                  name="住址"
                  label="住址"
                  placeholder="请输入住址"
                  @input="trimLR('address', 'familyData', index)"
                />
                <van-field
                  :clickable="familyIndex == index"
                  v-model="item.remark"
                  name="备注"
                  label="备注"
                  placeholder="请输入备注"
                  @input="trimLR('remark', 'familyData', index)"
                />
              </van-form>
            </div>
          </div>
          <div class="add-module-area" @click="addEvent('family')">+ 继续增加家庭成员</div>
        </div>
      </transition>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showEduPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onEduConfirm"
        @cancel="showEduPicker = false"
      />
    </van-popup>
    <van-popup v-model="showSexPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="sexData"
        @confirm="onfamilyConfirm"
        @cancel="showSexPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
import { Dialog } from "vant";
export default {
  name: 'personalData',
  data() {
    return {
      isShowEdu: false,
      isShowWork: false,
      isShowFamily: false,
      eduData: [],
      eduIndex: -1,
      workData: [],
      workIndex: -1,
      familyData: [],
      familyIndex: -1,
      status: '', // add: 添加, edit: 编辑
      type: '', // start: 开始时间, end: 结束时间
      module: '', // 操作模块
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2030, 11, 31),
      currentDate: new Date(),
      showPicker: false,
      showEduPicker: false,
      showSexPicker: false,
      columns: ["小学", "初中", "高中", "中专", "大专", "本科"],
      sexData: ["男", "女"]
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      if(val) {
        this.queryEduListEvent();
        this.queryWorkList();
        this.queryFamilyList();
      }
    }
  },
  // created() {
  //   this.queryEduListEvent();
  //   this.queryWorkList();
  //   this.queryFamilyList();
  // },
  methods: {
    dateReturnTimestamp: way.dateReturnTimestamp,
    timestampReturnDate: way.timestampReturnDate,
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    // 查询教育经历
    queryEduListEvent() {
      this.$request.queryEduList().then(res => {
        if(res.data.code == 0) {
          res.data.data.forEach(item => {
            item.startTime = this.timestampReturnDate(item.startTime);
            item.endTime = this.timestampReturnDate(item.endTime);
          })
          this.eduData = res.data.data;
        }
      })
    },
    // 查询工作经历
    queryWorkList() {
      this.$request.queryWorkList().then(res => {
        if(res.data.code == 0) {
          res.data.data.forEach(item => {
            item.startTime = this.timestampReturnDate(item.startTime);
            item.endTime = this.timestampReturnDate(item.endTime);
          })
          this.workData = res.data.data;
        }
      })
    },
    // 查询家庭成员
    queryFamilyList() {
      this.$request.queryFamilyList().then(res => {
        if(res.data.code == 0) {
          res.data.data.forEach(item => {
            item.sex = item.sex == '0' ? '男' : '女';
          })
          this.familyData = res.data.data;
        }
      })
    },
    trimLR(name, form = 'eduData', index) {
      this[form][index][name] = way.clearSpace(this[form][index][name]);
    },
    showTime(index, type) {
      if(this.module == 'edu') {
        if(this.eduIndex != index) return;
      } else {
        if(this.workIndex != index) return;
      }
      this.currentDate = new Date();
      this.showPicker = true;
      this.type = type;
    },
    showPosition(index) {
      if(this.eduIndex != index) return;
      this.showEduPicker = true;
      this.eduIndex = index;
    },
    showSex(index) {
      if(this.familyIndex != index) return;
      this.showSexPicker = true;
      this.familyIndex = index;
    },
    onConfirm(time) {
      let newTime = this.dateReturnTimestamp(time);
      let {startTime, endTime} = this.module == 'edu' ? this.eduData[this.eduIndex] : this.module == 'work' ? this.workData[this.workIndex] : this.familyData[this.familyIndex];
      if(this.type == 'start') {
        startTime = newTime;
        endTime = this.dateReturnTimestamp(endTime);
        if(startTime && endTime && startTime > endTime) {
          this.Error('开始时间不能大于结束时间');
          return;
        }
        newTime = this.timestampReturnDate(newTime)
        if(this.module == 'edu')
          this.eduData[this.eduIndex].startTime = newTime;
        else if(this.module == 'work')
          this.workData[this.workIndex].startTime = newTime;
      } else if(this.type == 'end') {
        endTime = newTime;
        startTime = this.dateReturnTimestamp(startTime);
        if(startTime && endTime && startTime > endTime) {
          this.Error('开始时间不能大于结束时间');
          return;
        }
        newTime = this.timestampReturnDate(newTime)
        if(this.module == 'edu')
          this.eduData[this.eduIndex].endTime = newTime;
        else if(this.module == 'work')
          this.workData[this.workIndex].endTime = newTime;
      }
      this.showPicker = false;
    },
    onEduConfirm(value) {
      this.eduData[this.eduIndex].position = value;
      this.showEduPicker = false;
    },
    onfamilyConfirm(value, index) {
      this.familyData[this.familyIndex].sex = value;
      this.showSexPicker = false;
    },
    // 编辑
    editEvent(index, type) {
      let title = type == 'edu' ? '教育经历' : type == 'work' ? '工作经历' : '家庭成员'
      if(this.status == 'add' || this.status == 'edit') {
        this.Error(`请完成当前的操作后，再继续编辑${title}`);
        return;
      }
      this.status = 'edit';
      this.module = type;
      if(type == 'edu') this.eduIndex = index;
      else if(type == 'work') this.workIndex = index;
      else this.familyIndex = index;
    },
    // 保存-教育经历
    enterEvent() {
      if(this.status == 'add') {
        this.addEduEvent();
      } else {
        this.editEduWorkEvent();
      }
    },
    // 保存-工作经历
    enterWorkEvent() {
      if(this.status == 'add') {
        this.addWorkEvent();
      } else {
        this.editEduWorkEvent();
      }
    },
    // 保存-家庭成员
    enterfamilyEvent() {
      if(this.status == 'add') {
        this.addFamilyEvent();
      } else {
        this.editFamilyEvent();
      }
    },
    addEvent(type) {
      let title = type == 'edu' ? '教育经历' : type == 'work' ? '工作经历' : '家庭成员'
      if(this.status == 'add' || this.status == 'edit') {
        this.Error(`请完成当前的操作后，再继续添加${title}`);
        return;
      }
      this.module = type;
      this.status = 'add';
      if(type == 'edu') {
        this.eduIndex = this.eduData.length;
        this.eduData.push({
          name: null,
          position: null,
          startTime: null,
          endTime: null,
          reference: null
        })
      } else if(type == 'work') {
        this.workIndex = this.workData.length;
        this.workData.push({
          name: null,
          startTime: null,
          endTime: null,
          position: null,
          reference: null,
        })
      } else {
        this.familyIndex = this.familyData.length;
        this.familyData.push({
          name: null,
          relation: null,
          age: null,
          sex: null,
          unit: null,
          phone: null,
          address: null
        })
      }
    },
    // 添加教育经历
    addEduEvent() {
      let obj = this.eduData[this.eduIndex];
      if(!obj.name || !obj.position || !obj.startTime || !obj.endTime) {
        this.Error('有必填项为填');
        return;
      }
      obj.startTime = this.dateReturnTimestamp(obj.startTime)
      obj.endTime = this.dateReturnTimestamp(obj.endTime)
      this.$request.addEduList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryEduListEvent();
          this.status = '';
          this.eduIndex = -1;
        }
      })
    },
    // 编辑
    editEduWorkEvent() {
      let obj;
      if(this.module == 'edu') {
        obj = JSON.parse(JSON.stringify(this.eduData[this.eduIndex]));
      } else {
        obj = JSON.parse(JSON.stringify(this.workData[this.workIndex]));
      }
      if(!obj.name || !obj.position || !obj.startTime || !obj.endTime) {
        this.Error('有必填项为填');
        return;
      }
      obj.startTime = this.dateReturnTimestamp(obj.startTime)
      obj.endTime = this.dateReturnTimestamp(obj.endTime)
      this.$request.updateEduList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.status = '';
          this.eduIndex = -1;
          this.workIndex = -1;
        }
      })
      
    },
    // 删除
    deleteEvent(index, type) {
      this.module = type;
      Dialog.confirm({
        title: '删除',
        message: '是否要删除该内容',
      })
      .then(() => {
        // on confirm
        if(type == 'edu') {
          if(this.status == 'add') {
            this.eduData.pop();
            this.eduIndex = -1;
            this.status = '';
            return;
          }
          this.eduIndex = index;
          this.deleteEduWork(type)
        } else if(type == 'work') {
          if(this.status == 'add') {
            this.workData.pop();
            this.workIndex = -1;
            this.status = '';
            return;
          }
          this.workIndex = index;
          this.deleteEduWork(type)
        } else {
          if(this.status == 'add') {
            this.familyData.pop();
            this.familyIndex = -1;
            this.status = '';
            return;
          }
          this.familyIndex = index;
          this.deleteFamily()
        }
      })
      .catch(() => {
        // on cancel
      });
    },
    // 删除教育经历
    deleteEduWork(type) {
      let id = type == 'edu' ? this.eduData[this.eduIndex].id : this.workData[this.workIndex].id;
      this.$request.deleteEduList({id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryEduListEvent();
          this.eduIndex = -1;
          this.workIndex = -1;
        }
      })
    },
    // 添加工作经历
    addWorkEvent() {
      let obj = this.workData[this.workIndex];
      if(!obj.name || !obj.position || !obj.startTime || !obj.endTime) {
        this.Error('有必填项为填');
        return;
      }
      obj.startTime = this.dateReturnTimestamp(obj.startTime)
      obj.endTime = this.dateReturnTimestamp(obj.endTime)
      this.$request.addWorkList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryWorkList();
          this.status = '';
          this.workIndex = -1;
        }
      })
    },
    // 添加家庭成员
    addFamilyEvent() {
      let obj = JSON.parse(JSON.stringify(this.familyData[this.familyIndex]));
      obj.sex = obj.sex == '男' ? 0 : 1;
      if(!obj.name || !obj.relation||!obj.unit || !obj.phone || !obj.age || !String(obj.sex)) {
        this.Error('有必填项为填');
        return;
      }
      this.$request.addFamilyList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryFamilyList();
          this.status = '';
          this.familyIndex = -1;
        }
      })
    },
    // 删除家庭成员
    deleteFamily() {
      let id = this.familyData[this.familyIndex].id;
      this.$request.deleteFamilyList({id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryFamilyList();
          this.familyIndex = -1;
        }
      })
    },
    // 编辑家庭成员
    editFamilyEvent() {
      let obj = JSON.parse(JSON.stringify(this.familyData[this.familyIndex]));
      obj.sex = obj.sex == '男' ? 0 : 1;
      if(!obj.name || !obj.relation || !obj.age || !String(obj.sex)) {
        this.Error('有必填项为填');
        return;
      }
      this.$request.updateFamilyList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.queryFamilyList();
          this.familyIndex = -1;
          this.status = '';
        }
      })
    },
    stretchEvent(type) {
      if(type == 'edu')
        this.isShowEdu = !this.isShowEdu;
      else if(type == 'work')
        this.isShowWork = !this.isShowWork;
      else
        this.isShowFamily = !this.isShowFamily;
    }
  },
}
</script>

<style lang="scss">
  .personalData {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 24px;
    background-color: #F9F9F9;
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
        opacity: 0
    }
    .per-area {
      &:not(:first-child) {
        .module-head {
          margin-top: 20px;
        }
      }
      .module-head {
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
        .stretch-btn {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
        }
      }
      .module-cont {
        margin-top: 10px;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        .module-cont-title {
          padding: 16px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          // border-bottom: 1px solid #ebedf0;
          display: flex;
          justify-content: space-between;
          .deleteEle {
            color: rgb(255, 43, 43);
            margin-right: 10px;
          }
        }
        .item-cont {
          // padding: 0 16px;
          .item-list-cont {
            padding: 18px 0;
            border-top: 1px solid #F9F9F9;
          }
        }
      }
      .add-module-area {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        padding: 18px 0;
        margin-top: 10px;
        text-align: center;
        background-color: #fff;
        border-radius: 10px;
      }
    }
  }
</style>